<template>
    <div v-if="spinner==true" class="spinner"></div>
    <section :class="content" :style="{ backgroundImage: 'url(' + require('@/assets/img/login-screen.webp') + ')', backgroundSize: 'cover', backgroundPosition: 'top right' }">
        <div class="version-tag">{{ version }}</div>
        <div class="container-fluid" style="height: calc(100% - 73px);">
            <div class="row d-flex justify-content-center align-items-center  ">
                <div class=" ">
                    
                    <form class="p-5 form-position"   >
                        <!-- Usuario input -->
                        <div class="form-outline mb-3">
                            <label class="form-label " for="usuario-login">Usuario</label>
                            <input type="text" id="usuario-login" placeholder="Introduce usuario" v-model="usuario" class="form-control form-control-lg"/>
                        </div>

                        <!-- Contraseña input -->
                        <div class="form-outline mb-3">
                            <label class="form-label " for="contrasenya-login">Contraseña</label>
                            <input type="password" id="contrasenya-login" placeholder="Introduce contraseña" v-model="password" class="form-control form-control-lg"/>
                        </div>

                        <div class="d-flex justify-content-between align-items-center">
                            <a href="#!" class="text-body ">¿Contraseña olvidada?</a>
                        </div>

                        <div class="text-center text-lg-start mt-4 pt-2">
                            <button type="button" class="btn btn-info btn-lg"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;" @click.prevent="login">Login</button>
                            <p class="small fw-bold mt-2 pt-1 mb-0 ">¿Quieres PWGS Quantum para tu negocio? <a href="https://reparadoreshogar.com/solicitar-una-demo-pwgs/"
                                class="link-danger " target="_blank"><strong><u>Solicita una demo</u></strong></a></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div
            class="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 ">
            <div class="mb-3 mb-md-0">
            Copyright &copy; 2025. Todos los derechos reservados.
            </div>
        </div>
        
    </section>
</template>
<style>

.form-position {
    position: absolute;
    top: 50%; /* Ajusta este valor para mover el formulario hacia arriba o abajo */
    right: 0%;
    transform: translate(-20%, -50%);
    width: 100%;
    max-width: 500px; /* Asegúrate de que el formulario no sea demasiado ancho */
    border: 1px solid #ccc; /* Borde gris fino */
    padding: 20px; /* Espaciado interno para que no quede pegado al borde */
    border-radius: 15px; /* Bordes ligeramente redondeados (opcional) */
    background: #fff; /* Fondo blanco para que se vea bien */
}

@media (max-width: 768px) {
    .form-position {
        top: 10%; /* Menor desplazamiento en pantallas pequeñas para evitar que se corte */
    }
}

.version-container {
    position: absolute;
    right: 30px;
    bottom: 30px;
    color: rgb(215, 211, 211); 
    font-size: 0.9rem;
}

.version-tag {
    position: absolute;
    right: 146px;  
    top: 965px; 
    border-radius: 5px;
    font-size: 12px;
    transform: rotate(9deg) skew(12deg); /* Ajusta la rotación y la deformación para alinear con la perspectiva */
    color: #cdcdcd;
    font-weight: bold;
    width: 200px;
    text-align: center;
}

</style>
<script>
import { PwgsApi } from './../../../services/PwgsApi.js'
import { ENTORNO_APUYEN } from './../../../../.env.js';
import { messaging, getToken } from '@/firebase';
//import { getMessaging, getToken } from "firebase/messaging";


export default {
    data() {
        return {
            usuario: null,
            password: null,
            direccion: "",
            version: '',
            errorlogin:'',
            spinner: false,
            content : 'vh-100',
        }
    },
    methods: {
        async login() {
            this.spinner = true;
            this.content = 'vh-100 difuminado';
            if (this.usuario.trim() === '' || this.password.length < 6)  {
                alert('Faltan datos. La contraseña debe tener mínimo 6 caracteres');
                return;
            }

            let username;  // Declarar username aquí para que esté disponible en todo el método
            let subdomain = '';

            if (this.usuario.includes('@')) {
                // Descomponer el nombre de usuario y dominio aquí
                const parts = this.usuario.split('@');
                username = parts[0];
                const domain = parts[1];
                subdomain = domain.split('.')[0];  // Extrae 'subdominio' de 'subdominio.pwgs.app'
                localStorage.setItem('subdomain', subdomain); // Guarda el subdominio para uso futuro
            } else {
                username = this.usuario;  // Usa el nombre de usuario tal como se proporcionó
                localStorage.removeItem('subdomain'); // Elimina el subdominio anterior si no se proporciona uno nuevo            
            }

            try {
                const servicio = new PwgsApi(subdomain);
                const api = new PwgsApi(subdomain);
                const datos = await servicio.login(username, this.password);
                this.errorlogin = datos;
                localStorage.setItem('nombreusuario', username);
                //localStorage.setItem('pwd', this.password);
                
                
                localStorage.setItem('token', datos.tokenId);
                localStorage.setItem('tramitador', username);
                
                const auxusuarios = await api.get('usuarios/'+datos.id_usuario);
                const datosusuarios = auxusuarios.datos;
                localStorage.setItem('usuario', datosusuarios.idusuario);
                localStorage.setItem('extension', datosusuarios.extension);
                this.historialurls(datosusuarios.idusuario);

                this.monitorizarErrores(this.usuario);
                this.meterurl();

                
                //Configuramos FIREBASE
                navigator.serviceWorker
                    .register('/firebase-messaging-sw.js')
                    .then((registration) => {
                        // Usa el service worker registrado en la obtención del token
                        getToken(messaging, { 
                            vapidKey: 'BF9dHbgLHL3lSgZ0lq5k8rrxP9iYcWTZHL8NU3cDQ48fEd6c0KZHVBEolRMprAfdZFmiv5fCUQriyOh_3WFf07Q',
                            serviceWorkerRegistration: registration
                        })
                        .then((token) => {
                            if (token) {
                                console.log('Token de dispositivo:', token);
                                
                                const api = new PwgsApi();
                                let subidadatos = { token_firebase: token };
                                api.put('usuarios/' + localStorage.getItem('usuario') + '/tokenFirebase/', subidadatos);

                                // Envía este token a tu servidor para asociarlo con el usuario
                            } else {
                                console.log('No se pudo obtener el token.');
                                console.log('No se pudo obtener el token');
                            }
                        })
                        .catch((err) => {
                            console.error('Error al obtener token:', err);
                            console.log('Error al obtener token:', err);
                        });
                    })
                    .catch((err) => {
                        console.log('Service worker registration failed: ', err);
                    });
               
                    /*
                //Escucha mensajes entrantes
                onMessage(messaging, (payload) => {
                    console.log('Notificación recibida:', payload);
                    alert('Notificación recibida:');
                    // Maneja la notificación 
                });
                */
                

                this.$router.push({
                    name: 'Escritorio'
                });
            } catch (error) {                                
                alert(this.errorlogin.descripcionInciencia);
                console.log("error",error);
                const api = new PwgsApi();
                api.get('logout'); 
                const apidos = new PwgsApi(subdomain);
                apidos.get('logout');
                localStorage.removeItem('token');
                this.spinner=false;
                this.content = 'vh-100';
            }
        },
        async logindos() {
            if (this.usuario.trim() === '' || this.password.length < 6) {
                alert('Faltan datos. La contraseña debe tener mínimo 6 caracteres');
                return;
            }
            const servicio = new PwgsApi();
            const datos = await servicio.login(this.usuario, this.password);
            //localStorage.setItem('token', 'c29wb3J0ZTdfMzQ1NzM5');
            localStorage.setItem('token', datos.tokenId);
            this.$router.push({
                name: 'Escritorio'
            });
        
        },
        meterurl() {
            var domain = "";
            if(localStorage.getItem('subdomain')!=''){
                domain = localStorage.getItem('subdomain')+".pwgs.app";
            }else{
                let aux = ENTORNO_APUYEN.BaseURL;
                const url = new URL(aux);
                domain = url.hostname;
            }
            
            localStorage.setItem('direccion', domain);

        },
        async historialurls(idusuario) {
            const api = new PwgsApi();
            let datosusuario = await api.get('usuarios/' + idusuario);
            this.$router.push('/escritorio');
            for (let i = 0; i < datosusuario.datos.pestanas.length; i++){
                let ruta = { path: datosusuario.datos.pestanas[i].path, fullPath: datosusuario.datos.pestanas[i].fullPath, name: datosusuario.datos.pestanas[i].name }
                this.$store.dispatch('agregarVentana', ruta);

            }
            

        },
        monitorizarErrores(usuario){

            var initOpts = {
                projectKey: "rMVhQP7dYnFONci3F4h7",
                ingestPoint: "https://monitorapp.grupoapuyen.net/ingest",
                defaultInputMode: 0,
                obscureTextNumbers: false,
                obscureTextEmails: true,
            };
            var startOpts = { userID: usuario };
            (function(A,s,a,y,e,r){
                r=window.OpenReplay=[e,r,y,[s-1, e]];
                s=document.createElement('script');s.src=A;s.async=!a;
                document.getElementsByTagName('head')[0].appendChild(s);
                r.start=function(){r.push([0])};
                r.stop=function(){r.push([1])};
                r.setUserID="function(id){r.push([2,id])}";
                r.setUserAnonymousID="function(id){r.push([3,id])}";
                r.setMetadata=function(k,v){r.push([4,k,v])};
                r.event=function(k,p,i){r.push([5,k,p,i])};
                r.issue=function(k,p){r.push([6,k,p])};
                r.isActive=function(){return false};
                r.getSessionToken=function(){};
            })("//static.openreplay.com/13.0.0/openreplay-assist.js",1,0,initOpts,startOpts);
        }
    }
}
</script>
<style scoped>
.difuminado{
        filter: blur(6px);
            pointer-events: none;
            -webkit-user-select: none;
                /* Safari */
            -ms-user-select: none;
                /* IE 10 and IE 11 */
            user-select: none;
                /* Standard syntax */
}
.card {
    width: 50%;
    margin: 20% auto;
}
.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 9px solid;
    border-color: #d9e3ff;
    border-right-color: #007bff;
    animation: spinner-d3wgkg 1s infinite linear;
    position: absolute;
    z-index:1000;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
         
}

@keyframes spinner-d3wgkg {
    to {
        transform: rotate(1turn);
    }
}
</style>